<template>
  <div class="space-y-2">
    <h1 class="flex items-center text-lg font-bold">
      Documenten

      <div v-if="false && rootGroups.length" class="ml-auto flex gap-2">
        <router-link :to="{ name: 'NewDocument' }" class="button pl-2">
          <plus-icon class="w-4" />
          <span class="whitespace-nowrap">Nieuw document</span>
        </router-link>
      </div>
    </h1>

    <router-view />

    <ul class="grid max-w-lg grid-cols-3 gap-4 sm:grid-cols-4">
      <li v-for="document in documents" :key="document.id">
        <a
          :href="`/api/documents/${document.id}`"
          target="_blank"
          class="flex flex-col items-center gap-2 rounded-xl bg-slate-100 px-2 py-3 text-center hover:bg-slate-200"
        >
          <document-icon class="w-10" />
          <span class="text-xs">{{ document.name }}</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
  import { inject, Ref, ref } from 'vue'
  import { useHead } from '@vueuse/head'
  import { DocumentIcon, PlusIcon } from '@heroicons/vue/24/solid'

  const documents = ref<GroupDocument[]>([])

  const groups = inject<Ref<Group[]>>('authorizedGroups')
  const rootGroups = groups?.value.filter((g) => g.parentId === null) || []

  const loadDocuments = async () => {
    const resp = await fetch('/api/documents')
    documents.value = await resp.json()
  }

  loadDocuments()

  useHead({ title: 'Documenten' })
</script>
