<template>
  <div
    class="fixed left-0 top-0 z-10 !mt-0 size-full bg-white/90 px-4"
    @click.self="dismiss"
  >
    <form
      @submit.prevent="createDocument"
      class="border- slate-200 relative mx-auto my-12 flex w-full max-w-md flex-col gap-4 border bg-slate-100 p-4 py-3 shadow-xl"
    >
      <h2 class="font-bold">Nieuw document</h2>

      <label class="flex flex-col gap-2">
        <span>Bestandsnaam</span>
        <input v-model="newDocument.name" class="rounded-md px-2 py-1" />
      </label>

      <label class="flex flex-col gap-2">
        <span>Bestand</span>
        <input @change="setFile" class="rounded-md px-2 py-1" />
      </label>
    </form>
  </div>
</template>

<script setup lang="ts">
  import { reactive } from 'vue'
  import { useRouter } from 'vue-router'

  const router = useRouter()
  const dismiss = () => {
    router.go(-1)
  }

  const newDocument = reactive({
    name: '',
    file: null,
  })

  const setFile = () => {}
  const createDocument = () => {}
</script>
